import React, {Fragment} from 'react';

const AboutComponent = () => {

    return(
        <Fragment>
            <p>This is about me.</p>
        </Fragment>
    )
}

export default AboutComponent;