import React, {Fragment} from 'react';
import { Link } from 'react-router-dom'

const MenuComponent = () => {

    return(
        <Fragment>
            <div className='panelDiv'>
                <div className='panel'>
                    <p>Qui suis-je ?</p>
                    <Link to='/about-me' className='arrow'>
                        <span className="left-arm"></span>
                        <span className="right-arm"></span>
                        <span className="middle-arm"></span>
                        <span className="arrow-slide"></span>
                    </Link>
            </div>
            <div className='panel'>
                <p>Mes créations</p>
                <Link to='/my-work' className='arrow'>
                    <span className="left-arm"></span>
                    <span className="right-arm"></span>
                    <span className="middle-arm"></span>
                    <span className="arrow-slide"></span>
                </Link>
            </div>
            <div className='panel'>
                <p>Contact</p>
                <Link to='/contact' className='arrow'>
                    <span className="left-arm"></span>
                    <span className="right-arm"></span>
                    <span className="middle-arm"></span>
                    <span className="arrow-slide"></span>
                </Link>
            </div>
        </div>
        <div className='footerDiv'>
            <p className='motto'>Vous en rêvez ?</p>
            <p className='motto'>Nous pouvons le réaliser.</p>
            <footer className='copyright'>Dreamarium © Tous droits réservés.</footer>
        </div>
            
        </Fragment>
    )
}

export default MenuComponent;

