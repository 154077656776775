import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals.js';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomeComponent from "./components/HomeComponent.js";
import MenuComponent from "./components/MenuComponent.js";
import AboutComponent from './components/AboutComponent.js';
import WorkComponent from './components/WorkComponent.js';

const router = createBrowserRouter([
  { 
    path: '/',
    element : <HomeComponent />,
    children: [
      {
        path: "menu",
        element: <MenuComponent/>,
      },
      {
        path: "about-me",
        element: <AboutComponent />,
      },
      {
        path: "my-work",
        element: <WorkComponent />,
      },
    ],
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(

  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
