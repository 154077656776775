import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import useSound from 'use-sound';
import marioPipe from '../img/marioPipe.png';
import plainsBackground from '../img/plainsBackground.png';
import questionMarkBlock from '../img/questionMarkBlock.png';
import emptyBlock from '../img/emptyBlock.png';
import pipeAudio from '../audio/going-down-pipe-sfx.mp3';
import star from '../img/star.png';
import note from '../img/note.png';
import characterImg from '../img/characterImg.png';
import './../App.css';
import * as HelperFunctions from '../helperFunctions.js';

const HomeComponent = () => {

  const [time, setTime] = useState(Date.now());
  const [isUserInMenu, setIsUserInMenu] = useState(false);
  const [isInfoTabOpen, setIsInfoTabOpen] = useState(false);
  const [isDeviceRotated, setIsDeviceRotated] = useState(false);
  const [collectedStrings, setCollectedStrings] = useState([]);
  const [pipeSound] = useSound(pipeAudio);
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const canvasRef = useRef(null);
  const platforms = [
    { x: 0, y: 60, width: 120, height: 1},
    { x: 120, y: 75, width: 15, height: 15},
    { x: 145, y: 90, width: 40, height: 1},
    { x: 450, y: 150, width: 190, height: 1 },
    { x: 520, y: 40, width: 90, height: 1 },
  ];
  const questionMarkBlockPositions = [
    { x: 230, y: 150, width: 20, height: 20, active: true, content: "Hello" },
    { x: 250, y: 150, width: 20, height: 20, active: true, content: "World" },
    { x: 230, y: 100, width: 20, height: 20, active: true, content: "!" },
  ];
  const gravityMultiplier = 0.5; // Controls the gravity strength

  const pipeHeight = screenHeight / 10; // Screen height divided by 10 = 10% from css
  const pipeWidth = screenWidth / 5 // Screen width divided by 5 = 20% from css

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);

    const handleOrientationChange = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      setIsDeviceRotated(isLandscape);
    }

    handleOrientationChange();

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      setInterval(() => {}, 1000);
      clearInterval(interval);
    };
  }, [time]);

  useEffect(() => {
    console.log(collectedStrings.join('-'));
  }, [collectedStrings])

  const transitionToLevel = () => {
    const starImg = document.getElementById('starImg');

    if(starImg.classList.contains('star-transition')) {
      starImg.classList.remove('star-transition');
      document.body.classList.remove('black-screen');
      setIsUserInMenu(false);
    } else {
      starImg.classList.add('star-transition');
      document.body.classList.add('black-screen');
      setTimeout( () => {
        setIsUserInMenu(true);
      },1500);
    }
  };

  const startGame = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = plainsBackground;

    // Character properties
    const character = {
      x: 50,
      y: canvas.height - 50,
      width: 20,
      height: 40,
      color: '#3498db',
      sprite: characterImg,
      velocityY: 0,
      jumping: false,
      jumpPower: 10,
    };
    
    // Keyboard input
    const keys = {};
    
    window.addEventListener('keydown', (e) => {
      keys[e.code] = true;
      
    });
    
    window.addEventListener('keyup', (e) => {
      keys[e.code] = false;
    });

    // Define custom ground points
    const groundPoints = [
      { x: 0, y: 310},
      { x: 50, y: 310 },
      { x: 100, y: 340 },
      { x: 220, y: 340 },
      { x: 300, y: 270 },
      { x: 390, y: 270 },
      { x: 390, y: 310 },
      { x: 440, y: 310 },
      { x: 470, y: 340 },
      { x: 530, y: 340 },
      { x: 530, y: 310 },
      { x: 667, y: 310 }
    ];

    // Check if the game is already started
    if (!canvas.getAttribute('data-game-started')) {
      // Set attribute to mark that the game has started
      canvas.setAttribute('data-game-started', 'true');

      // Update function
      function update() {

        // Move left
        if (keys['ArrowLeft'] && character.x > 0)
          character.x -= 2;
        
        // Move right
        if (keys['ArrowRight'] && character.x < canvas.width - character.width)
          character.x += 2;
        
        // Jump
        if (keys['Space'] && !character.jumping) {
          character.velocityY = -character.jumpPower;
          character.jumping = true;
        }
        
        // Apply gravity
        character.velocityY += 0.8 * gravityMultiplier;

        // Cap the maximum downward velocity
        if (character.velocityY > 10)
          character.velocityY = 10;

        character.y += character.velocityY;
        if(!character.jumping === true) character.y += 0;
        
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the image
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Draw the blocks
        questionMarkBlockPositions.forEach((block) => {
          if(block.active)
            ctx.drawImage(document.getElementById('questionMarkBlock'), block.x, block.y, block.width, block.height);
          else
            ctx.drawImage(document.getElementById('emptyBlock'), block.x, block.y, block.width, block.height);
        });
        
        // Draw the character
        ctx.drawImage(document.getElementById('characterImg'), character.x, character.y, character.width, character.height)
        
        // Check for ground collision
        if (character.y > HelperFunctions.getGroundHeight(character.x, groundPoints, canvas) - character.height) {
          character.y = HelperFunctions.getGroundHeight(character.x, groundPoints, canvas) - character.height;
          character.jumping = false;
        }

        // Check for collisions with platforms
        for (const platform of platforms) {
          if (
            character.x < platform.x + platform.width &&
            character.x + character.width > platform.x &&
            character.y < platform.y + platform.height &&
            character.y + character.height > platform.y
          ) {
            // Collision detected, adjust character position
            // Stop falling when character is on a platform
            character.y = platform.y - character.height;
            character.jumping = false;
          }
        }

        // Check for collision with blocks
        questionMarkBlockPositions.forEach((block) => {
          const collision = HelperFunctions.isColliding(character, block);
          if (collision && character.velocityY > 0 && block.active) {
            // If character is hitting the block from below and the block is active
            // Perform the action (e.g., make something appear)
            HelperFunctions.handleBlockHit(character, block, setCollectedStrings);

            // Adjust character position to be on top of the block
            character.y = block.y - character.height;

            // Reset jumping state
            character.jumping = false;
            character.velocityY = 0;
          }
          else if(collision && character.velocityY > 0 && !block.active){
            character.y = block.y - character.height;
            character.jumping = false;
          }
        });
        
        // Call update again
        requestAnimationFrame(update);
      }

      update();
    }
  }

  return (
    <div>
      {!isUserInMenu ? (
      <div>
        <div className='starContainer'>
          <img src={star} id="starImg" alt=""/>
        </div>
        <div className="jumbotron hero" id="home">
          <Container>
            <h1>
              Bienvenue chez <span>Dreamarium</span>
            </h1> 
            <p>
              Votre porte vers un monde de <span>rêves</span> et <span>d'imagination</span>
            </p>
            <div className='mario-pipe'>
              <img 
                src={marioPipe}
                style={{position: 'fixed', bottom: 0 - pipeHeight/4, left: screenWidth/2 - pipeWidth/2  }} 
                id="pipeImg" 
                alt="" 
                onClick={() => {transitionToLevel(); pipeSound()}}/>
            </div>
          </Container>
        </div>
        <Outlet />
      </div>
      ) : (
      <div>
        {isDeviceRotated ? (
          <div className='levels-display'>
            <canvas 
              ref={canvasRef}
              id="gameCanvas" 
              width={screenWidth} 
              height={screenHeight} 
              onClick={startGame}
              style={{ zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
              <img src={questionMarkBlock} id="questionMarkBlock" alt=''/>
              <img src={emptyBlock} id="emptyBlock" alt=''/>
              <img src={characterImg} id="characterImg" alt=''/>
            </canvas>
            <button onClick={() => setIsInfoTabOpen(!isInfoTabOpen)}><img src={note} alt=""/></button>
            {isInfoTabOpen && (
              <div className="menu">
                <h2>Collected Informations</h2>
                <ul>
                  {collectedStrings.map((string, index) => (
                    <li key={index}>{string}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )  : (
      <div>
        <h1 className='please-rotate'>Please rotate your device.</h1>
      </div>
      
    )}
      </div>
      )}
    </div>
  );
};

export default HomeComponent;