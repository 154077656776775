// Function to get ground height at a specific x coordinate
export function getGroundHeight(x, groundPoints, canvas) {
    for (let i = 0; i < groundPoints.length - 1; i++) {
        if (x >= groundPoints[i].x && x <= groundPoints[i + 1].x) {
            const t = (x - groundPoints[i].x) / (groundPoints[i + 1].x - groundPoints[i].x);
            return (1 - t) * groundPoints[i].y + t * groundPoints[i + 1].y;
        }
    }
return canvas.height; // Default to canvas height if not found
};

// Helper function to check if two rectangles are colliding
export function isColliding(rectA, rectB) {
    return (
        rectA.x < rectB.x + rectB.width &&
        rectA.x + rectA.width > rectB.x &&
        rectA.y < rectB.y + rectB.height &&
        rectA.y + rectA.height > rectB.y
    );
}

export function handleBlockHit(character, block, setCollectedStrings) {
    if (block.active) {
        block.active = false; // Deactivate the block

        // Add the string content to the collected strings
        setCollectedStrings(prevStrings => [...prevStrings, block.content]);

        // Adjust character position to move down
        character.y += 10; // Adjust the value based on how much you want the character to move down

        // Reset jumping state
        character.jumping = false;
        character.velocityY = 0;
    }
}