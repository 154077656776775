import React, {Fragment} from 'react';

const WorkComponent = () => {

    return(
        <Fragment>
            <p>This is about my work.</p>
        </Fragment>
    )
}

export default WorkComponent;